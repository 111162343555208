export interface Sponsor {
    name: string,
    imagePath: string,
    url: string,
}

export const Partners: Sponsor[] = [
    {
        name:"Hand in Hand",
        imagePath: "HIH-Logo.jpeg",
        url: "https://handinhandkids.org/"
    },
    {
        name:"Everett Jets FC",
        imagePath: "Everett Jets FC Logo.jpeg",
        url: "https://www.everettjetsfc.com/"
    },
    {
        name:"LS Football",
        imagePath: "ls-football.jpeg",
        url: "https://www.facebook.com/lsfootball10/"
    },
    {
        name:"Everett Junior Cheer",
        imagePath: "EJC LOGO.jpeg",
        url: "https://www.everettjuniorcheer.com/"
    },
    {
        name:"Everett Downtown Storage",
        imagePath: "everett-downtown-storage-logo.png",
        url: "https://everettdowntownstorage.com/"
    },
];

export const SponsorsList: Sponsor[] = [
    {
        name:"Ziply Fiber",
        imagePath: "ziply-fiber.jpeg",
        url: "https://ziplyfiber.com/"
    },
    {
        name:"PACE Engineers, Inc.",
        imagePath: "PACE_Logo.png",
        url: "https://www.paceengrs.com/"
    },
    {
        name:"Jimmy John's",
        imagePath: "Jimmy_Johns.png",
        url: "https://www.jimmyjohns.com/"
    },
    {
        name:"Dick's Sporting Goods",
        imagePath: "dicks-sporting-goods.jpeg",
        url: "https://www.dickssportinggoods.com/"
    },
    {
        name:"Seattle Children's",
        imagePath: "childrens-north-everett.png",
        url: "https://tinyurl.com/8ecdcfyc"
    },
    {
        name:"Chaison Orthodontics",
        imagePath: "Chaison-Logo.png",
        url: "https://www.chaisonortho.com/"
    },
    {
        name:"Beverly Auto Licensing",
        imagePath: "Bevs_Logo.JPG",
        url: "https://www.facebook.com/bevsautolicensing/"
    },
    {
        name:"Vander Veen Construction",
        imagePath: "vvc_logo.png",
        url: "https://www.vanderveenco.com/"
    },
    {
        name:"Everett Downtown Storage",
        imagePath: "everett-downtown-storage-logo.png",
        url: "https://everettdowntownstorage.com/"
    },
    {
        name:"Red Robin",
        imagePath: "red_robin.png",
        url: "https://www.redrobin.com/kids-half"
    },
];